import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import LandingPage from './Components/LandingPage';
import LaunchConsent from './Components/LaunchConsent';
import Resume from './Components/Resume';
import Consented from './Components/ConsentedWithJobId';
import ConsentedSuccess from './Components/ConsentedSuccess';
import ShowMissed from './Components/ShowMissed';
import EntryPage from './Components/EntryPage';
import Income from './Components/Income'
import WeCanHelp from './Components/WeCanHelp';
import ReactGA from 'react-ga4';

function initializeAnalytics() {
  ReactGA.initialize('G-PR9SDXYGL0');
}


function App() {
  useEffect(() => {
    initializeAnalytics();
  }, []);

  return (
    <Router>
    <Routes>
  <Route path="/LandingPage" element={<LandingPage />} />
  <Route path="/" element={<EntryPage />} />
  <Route path="/Income" element={<Income />} />
  <Route path="/WeCanHelp" element={<WeCanHelp />} />
  <Route path="/LaunchConsent" element={<LaunchConsent />} />
  <Route path="/Resume" element={<Resume />} />
  <Route path="/Consented" element={<Consented />} />
  <Route path="/ConsentedSuccess" element={<ConsentedSuccess />} />
  <Route path="/ShowMissed" element={<ShowMissed />} />
</Routes>
</Router>
);
}

export default App;

