import React, { useEffect, useState } from 'react';
import './shared.css';
import Logo from './Images/visalogo.png';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

function Resume() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    const queryParams = new URLSearchParams(window.location.search);
    const existingValue = queryParams.get('existing') || 'false'; 
    const params = queryParams;


    if (existingValue === 'successful') {
      const path = '/ConsentedSuccess';
      navigate(`${path}?${params.toString()}`);
    }
  }, [navigate]);

  const handleClick = () => {
    setIsSubmitting(true);
    navigate('/LaunchConsent');
  };

  return (
    <div className="container">
      <div className="logo-box">
        <img src={Logo} alt="Logo" className="logo-image" />
      </div>
      <div className="header">
        <h1 className="title">Unlock Missed Rewards</h1>
        <p className="subtitle">Did you know that most people are missing out on a mountain of cashback over a 12 month period?</p>
      </div>
      <div className="points-display">
        <div className="points">$2,460</div>
        <div className="points-label">*Avg Missed Cashback</div>
      </div>
      <p className="title">Want to find out what you are missing?</p>
      <button onClick={handleClick} className="submit-email-button" disabled={isSubmitting}>
        {isSubmitting ? 'Loading...' : `Let's Go`}
      </button>
    </div>
  );
}

export default Resume;
