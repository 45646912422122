import React, { useEffect, useState } from 'react';
import './shared.css';
import Logo from './Images/visalogo.png';
import ReactGA from 'react-ga4';
import MissedRewards from './Forms/MissedRewards';
import RewardsList from './Forms/RewardsList';


function ShowMissed() {

      const [isSubmitting, setIsSubmitting] = useState(false);
      const [textToShow, setTextToShow] = useState('')
      const [submittedMessage, setSubmittedMessage] = useState('');
      const cdrid = localStorage.getItem('cdrid');
   
      useEffect(() => {
       ReactGA.send({ hitType: "pageview", page: window.location.pathname });
       }, []);
      
      

      const handleDataLoaded = (totalCashBack) => {
        console.log('received total cashback:', totalCashBack);
    
        const goal = localStorage.getItem('goal');
        chooseTextToShow(goal, totalCashBack);
    };
       
        const chooseTextToShow = (goal, totalCashBackNumber) => {

          let newText = 'We found your missing money!'

        if (goal === 'save money') {
          newText = 'You could have saved so much!';
        } 
        else  if (goal === 'pay off my debt') {
          newText = 'You could have reduced your debt by heaps!';
        } 
        else  if (goal === 'buy a house') {
          newText = 'This would be great toward your home deposit';
        } 
        else  if (goal === 'go on a dream trip') {
          if (totalCashBackNumber > 10000) {
            newText = 'You could have flown return business to EUROPE!';
          }
          else if (totalCashBackNumber > 5000) {
            newText = 'You could have flown return to EUROPE!';
          }
          else if (totalCashBackNumber > 2000) {
            newText = 'You could have flown return to ASIA!';
          }
          else if (totalCashBackNumber > 1000) {
            newText = 'You could have gone on a weekend away!';
          }
          else if (totalCashBackNumber < 499)
          {
            newText = 'You could have gone on a weekend away';
          }
        } 
      
        setTextToShow(newText)
        console.log(newText);
};
   

const handleClick = async () => {
  setIsSubmitting(true);
  try {
    
      const response = await fetch('https://minerva.sipora.io/api/client/rewards-optimiser/notify', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'tenant': 'VISA',
          },
          body: JSON.stringify({ cdrid })
      });

      if (response.ok) {
          setSubmittedMessage('It is now safe to close this window.');
      } else {
          setSubmittedMessage('Something went wrong, please close this window and try again.');
      }
  } catch (error) {
      console.error('Error:', error);
      setSubmittedMessage('Something went wrong, please close this window and try again.');
  } finally {
      setIsSubmitting(false);
  }
};



return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>
  <div className="header">
  <h1 className="title">WOAH!</h1>
<h1 className="title">{textToShow}</h1>
  </div>
  {<MissedRewards onDataLoaded={handleDataLoaded}/>}

  <p className="title">From shopping at your favourite stores:</p>

  {<RewardsList />}
  <p className="title" align="center">Do you want us to email us you with instructions on how to claim your rewards?</p>

  <button onClick={handleClick} className="submit-email-button" disabled={isSubmitting}>
        {isSubmitting ? 'Redirecting...' : `Yes! Email Me`}</button>
  <p className="title" align="center">{submittedMessage}</p>
</div>

)
}

export default ShowMissed;