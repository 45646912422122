import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import '../../Components/shared.css';

function NotifyButton() {
  const [buttonState, setButtonState] = useState({
    text: 'Send my results via email',
    disabled: false,
  });

  const cdrid = localStorage.getItem('cdrid');
  const tenant = 'VISA';

  const submitCdrid = async () => {
    // Immediately indicate the button has been clicked and disable it
    setButtonState({ text: 'Processing...', disabled: true });

    ReactGA.event({
      category: 'User',
      action: 'Notify Me Button',
      label: buttonState.text
    });

    try {
      const response = await fetch('https://minerva.sipora.io/api/client/rewards-optimiser/wait', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cdrid, tenant }),
      });

      if (response.ok) {
        // Change the button state to show success message
        setButtonState({ text: "Expect to hear from us in the next few minues", disabled: true });
      } else {
        // Handle non-successful response by indicating failure and re-enabling the button
        setButtonState({ text: 'Failed to Notify, Try Again', disabled: false });
      }

    } catch (error) {
      console.error('Error:', error);
      // In case of an error, update the button to reflect the error and allow retry
      setButtonState({ text: 'Error Occurred, Try Again', disabled: false });
    }
  };

  return (
    <button 
      className="submit-email-button" 
      onClick={submitCdrid} 
      disabled={buttonState.disabled} 
      type="button">
      {buttonState.text}
    </button>
  );
}

export default NotifyButton;
