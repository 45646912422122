import ReactGA from 'react-ga4';
import React, { useEffect } from 'react';
import './shared.css';
import Logo from './Images/visalogo.png';
import RegisterConsent from './Forms/RegisterConsent';
import Lock from './Images/lock.png';

function LaunchConsent() {
  useEffect(() => {
    // Send a page view event to Google Analytics
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const queryParams = new URLSearchParams(window.location.search);
  const cdrid = queryParams.get('cdrid');
  
    
return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
           </div>
  
  <div className="header">
      
    <h1 className="title">One step away to see the rewards you missed</h1>
    {<img src={Lock} alt="Logo" className="hero-image" />}     
    <p className="subtitle">Leave the heavy lifting to us.</p>
    <p className="subtitle"> Easily connect your bank accounts on the next screen and we will do the math on the rewards you missed.​</p>
    <p className="subtitle">Don’t worry, our platform is rock-solid! We just need this to analyse your transactions.​</p>
  </div>
  

  <RegisterConsent cdrid={cdrid}/>
</div>

)
}

export default LaunchConsent;