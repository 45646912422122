import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Components/shared.css';


function RegisterEmail() {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const goal = localStorage.getItem('goal');
  const [loadingMessage, setLoadingMessage] = useState('');
  


  const submitEmail = async (email) => {
   
    try {
       const response = await fetch('https://api.cdr.sipora.io/launchro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'tenantId': 'VISA',
          'apiKey': 'Rxra1NUqek3lzuoRhYhGMeTk6bvMhv7gOdOBdOEsmkUbBcJBatYYNGJNZecSyDdj' //PASS FROM APP
        },
        body: JSON.stringify({ email, 'goal': goal }),
      });

      const responseData = await response.json();
     

      if (response.ok) {
   
        const urlString = responseData.roLink;
        const url = new URL(urlString);
        const params = url.searchParams;
        const existingValue = params.get('existing') || 'false';
        if ( existingValue !== 'successful' ) {
          const path = '/LaunchConsent';
          navigate(`${path}?${params.toString()}`);
            return;
        }

        // Use navigate from react-router-dom for navigation, avoiding window.location.href
        const path = '/Resume';
        navigate(`${path}?${params.toString()}`);
      } else {
        // Handle non-successful responses
        console.error('Error submitting email:', responseData.message);
        alert(responseData.message || 'Error submitting email');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the email.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setLoadingMessage('This might take a minute or two');
    await submitEmail(email);
    
  };

  return (
    <div className="form-container">
    <form onSubmit={handleSubmit} className="email-form-field">
      
      <input
        type="email"
        id="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="email-input"
        required
        autoComplete="email"
      />
      <button className="submit-email-button" type="submit" disabled={isSubmitting} >
      {isSubmitting ? 'Registering...' : `Let's go`}</button>

        <p className="subtitle">
          {loadingMessage}
        </p>
    </form></div>
  );
}

export default RegisterEmail;
